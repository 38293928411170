var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("update", "users")
    ? _c(
        "div",
        [
          _c(
            "b-media",
            {
              staticClass: "mb-2",
              scopedSlots: _vm._u(
                [
                  {
                    key: "aside",
                    fn: function() {
                      return [
                        _c(
                          "image-uploader",
                          {
                            attrs: {
                              "class-name": "fileinput",
                              debug: 2,
                              "max-width": 100,
                              "max-height": 100,
                              quality: 0.8,
                              "output-format": "string",
                              preview: false,
                              capture: false,
                              accept: ".jpg"
                            },
                            on: { input: _vm.inputAvatar }
                          },
                          [
                            _c(
                              "label",
                              {
                                attrs: {
                                  slot: "upload-label",
                                  for: "fileInput"
                                },
                                slot: "upload-label"
                              },
                              [
                                _c("b-avatar", {
                                  ref: "previewEl",
                                  attrs: {
                                    src: _vm.theAvatar,
                                    text: _vm.avatarText(_vm.userData.fullName),
                                    variant:
                                      "light-" +
                                      _vm.resolveUserRoleVariant(
                                        _vm.userData.role
                                      ),
                                    size: "90px",
                                    rounded: ""
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3321422128
              )
            },
            [
              _c("h4", { staticClass: "mb-1" }, [
                _vm._v(" " + _vm._s(_vm.userData.fullName) + " ")
              ])
            ]
          ),
          _c("validation-observer", {
            ref: "observer",
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var handleSubmit = ref.handleSubmit
                    return [
                      _c(
                        "b-form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return handleSubmit(_vm.validateForm)
                            }
                          }
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Username",
                                        "label-for": "username"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "username",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      id: "username",
                                                      state:
                                                        errors.length > 0
                                                          ? false
                                                          : null,
                                                      readonly: true,
                                                      name: "username"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.userData.username,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.userData,
                                                          "username",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "userData.username"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Client Name",
                                        "label-for": "client_display_name"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "client_display_name",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      id: "client_display_name",
                                                      readonly: true
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.userData
                                                          .client_display_name,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.userData,
                                                          "client_display_name",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "userData.client_display_name"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Email",
                                        "label-for": "email"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "email",
                                          rules: "required|email"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      id: "email",
                                                      type: "email",
                                                      readonly: true
                                                    },
                                                    model: {
                                                      value: _vm.userData.email,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.userData,
                                                          "email",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "userData.email"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "First Name",
                                        "label-for": "first_name"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "first_name",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("b-form-input", {
                                                    attrs: { id: "first_name" },
                                                    model: {
                                                      value:
                                                        _vm.userData.first_name,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.userData,
                                                          "first_name",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "userData.first_name"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "LastName",
                                        "label-for": "last_name"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "last_name",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("b-form-input", {
                                                    attrs: { id: "last_name" },
                                                    model: {
                                                      value:
                                                        _vm.userData.last_name,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.userData,
                                                          "last_name",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "userData.last_name"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Phone",
                                        "label-for": "phone"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: { name: "phone", size: 10 },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      id: "phone",
                                                      placeholder: "optional"
                                                    },
                                                    model: {
                                                      value: _vm.userData.phone,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.userData,
                                                          "phone",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "userData.phone"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "User Role",
                                        "label-for": "cognito_group"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "cognito_group",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("v-select", {
                                                    attrs: {
                                                      dir: _vm.$store.state
                                                        .appConfig.isRTL
                                                        ? "rtl"
                                                        : "ltr",
                                                      options: _vm.roleOptions,
                                                      reduce: function(val) {
                                                        return val.value
                                                      },
                                                      clearable: false,
                                                      "input-id":
                                                        "cognito_group"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.userData
                                                          .cognito_group,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.userData,
                                                          "cognito_group",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "userData.cognito_group"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Region",
                                        "label-for": "region_loc"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "region_loc",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("v-select", {
                                                    attrs: {
                                                      dir: _vm.$store.state
                                                        .appConfig.isRTL
                                                        ? "rtl"
                                                        : "ltr",
                                                      options:
                                                        _vm.regionOptions,
                                                      reduce: function(val) {
                                                        return val.value
                                                      },
                                                      clearable: false,
                                                      "input-id": "region_loc",
                                                      rules: "required"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.userData.region_loc,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.userData,
                                                          "region_loc",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "userData.region_loc"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.userData.group === "Management" ||
                          _vm.userData.group === "EyeTrax_Admin"
                            ? _c(
                                "b-row",
                                {
                                  staticClass: "mt-2 mb-1 border-bottom-primary"
                                },
                                [
                                  _c("b-col", [
                                    _c("h4", [
                                      _vm._v("Security Services Information")
                                    ])
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Force Onboarding",
                                        "label-for": "is_onboarding"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "is_onboarding",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("v-select", {
                                                    attrs: {
                                                      dir: _vm.$store.state
                                                        .appConfig.isRTL
                                                        ? "rtl"
                                                        : "ltr",
                                                      options:
                                                        _vm.onboardingOptions,
                                                      reduce: function(val) {
                                                        return val.value
                                                      },
                                                      clearable: false,
                                                      "input-id":
                                                        "is_onboarding"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.userData
                                                          .is_onboarding,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.userData,
                                                          "is_onboarding",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "userData.is_onboarding"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Status",
                                        "label-for": "is_active"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "is_active",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("v-select", {
                                                    attrs: {
                                                      dir: _vm.$store.state
                                                        .appConfig.isRTL
                                                        ? "rtl"
                                                        : "ltr",
                                                      options:
                                                        _vm.statusOptions,
                                                      reduce: function(val) {
                                                        return val.value
                                                      },
                                                      clearable: false,
                                                      "input-id": "is_active"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.userData.is_active,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.userData,
                                                          "is_active",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "userData.is_active"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "2" } },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                      attrs: {
                                        variant: "primary",
                                        block: "",
                                        type: "submit"
                                      }
                                    },
                                    [_vm._v(" Save Changes ")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "2" } },
                                [
                                  _vm.$can("delete", "users")
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                          attrs: {
                                            variant: "danger",
                                            block: ""
                                          },
                                          on: { click: _vm.deleteUser }
                                        },
                                        [_vm._v(" Delete User ")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              3994757857
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }