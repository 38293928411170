var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("update", "users")
    ? _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c("b-card-header", { staticClass: "pb-50" }, [
            _c("h5", [_vm._v(" Authorized Client Sites ")])
          ]),
          _c(
            "b-card-body",
            [
              _c(
                "b-row",
                { staticClass: "mb-2 mt-1" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-start mb-1 mb-md-0",
                      attrs: { cols: "12", md: "6" }
                    },
                    [
                      _c("label", [_vm._v("Show")]),
                      _c("v-select", {
                        staticClass: "per-page-selector d-inline-block mx-50",
                        attrs: {
                          dir: _vm.$store.state.appConfig.isRTL ? "rtl" : "ltr",
                          options: _vm.perPageOptions,
                          clearable: false
                        },
                        model: {
                          value: _vm.perPage,
                          callback: function($$v) {
                            _vm.perPage = $$v
                          },
                          expression: "perPage"
                        }
                      }),
                      _c("label", [_vm._v("entries")]),
                      _c(
                        "b-button",
                        {
                          staticClass: "ml-2",
                          attrs: {
                            variant: "primary",
                            disabled: this.$store.state.clients
                              .fetchingClientSitesList
                          },
                          on: { click: _vm.refreshClientSitesList }
                        },
                        [
                          _c("feather-icon", {
                            attrs: { icon: "RefreshCcwIcon" }
                          }),
                          _c("span", { staticClass: "text-nowrap ml-1" }, [
                            _vm._v("Refresh")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "ml-2",
                          attrs: { variant: "primary" },
                          on: { click: _vm.selectAllRows }
                        },
                        [
                          _c("feather-icon", {
                            attrs: { icon: "CheckSquareIcon" }
                          }),
                          _c("span", { staticClass: "text-nowrap ml-1" }, [
                            _vm._v("Select All")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "ml-2",
                          attrs: { variant: "primary" },
                          on: { click: _vm.clearSelected }
                        },
                        [
                          _c("feather-icon", {
                            attrs: { icon: "MinusSquareIcon" }
                          }),
                          _c("span", { staticClass: "text-nowrap ml-1" }, [
                            _vm._v("Clear")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-end"
                      },
                      [
                        _c("b-form-input", {
                          staticClass: "d-inline-block mr-1",
                          attrs: { placeholder: "Search..." },
                          model: {
                            value: _vm.searchQuery,
                            callback: function($$v) {
                              _vm.searchQuery = $$v
                            },
                            expression: "searchQuery"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("b-table", {
                        ref: "refClientSitesListTable",
                        attrs: {
                          selectable: "",
                          hover: "",
                          responsive: "",
                          "show-empty": "",
                          "empty-text": "",
                          items: _vm.fetchClientSites,
                          fields: _vm.tableColumns,
                          "primary-key": "sitename",
                          "sort-by": _vm.sortBy,
                          "sort-desc": _vm.isSortDirDesc,
                          busy: _vm.isBusy
                        },
                        on: {
                          "update:sortBy": function($event) {
                            _vm.sortBy = $event
                          },
                          "update:sort-by": function($event) {
                            _vm.sortBy = $event
                          },
                          "update:sortDesc": function($event) {
                            _vm.isSortDirDesc = $event
                          },
                          "update:sort-desc": function($event) {
                            _vm.isSortDirDesc = $event
                          },
                          "update:busy": function($event) {
                            _vm.isBusy = $event
                          },
                          "row-selected": _vm.onRowSelected
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cell(sitename)",
                              fn: function(data) {
                                return [
                                  _c("div", { staticClass: "text-nowrap" }, [
                                    _vm._v(
                                      " " + _vm._s(data.item.sitename) + " "
                                    )
                                  ])
                                ]
                              }
                            },
                            {
                              key: "cell(client_display_name)",
                              fn: function(data) {
                                return [
                                  _c("div", { staticClass: "text-nowrap" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(data.item.client_display_name) +
                                        " "
                                    )
                                  ])
                                ]
                              }
                            },
                            {
                              key: "cell(status)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "b-badge",
                                    {
                                      staticClass: "text-capitalize",
                                      attrs: {
                                        pill: "",
                                        variant:
                                          "light-" +
                                          _vm.resolveClientSitesStatusVariant(
                                            data.item.status
                                          )
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(data.item.status) + " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2564950484
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mb-2 mt-1" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-center justify-content-sm-start",
                      attrs: { cols: "12", sm: "6" }
                    },
                    [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v(
                          "Showing " +
                            _vm._s(_vm.dataMeta.from) +
                            " to " +
                            _vm._s(_vm.dataMeta.to) +
                            " of " +
                            _vm._s(_vm.dataMeta.of) +
                            " entries"
                        )
                      ])
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-center justify-content-sm-end",
                      attrs: { cols: "12", sm: "6" }
                    },
                    [
                      _c("b-pagination", {
                        staticClass: "mb-0 mt-1 mt-sm-0",
                        attrs: {
                          "total-rows": _vm.totalClientSites,
                          "per-page": _vm.perPage,
                          "first-number": "",
                          "last-number": "",
                          "prev-class": "prev-item",
                          "next-class": "next-item"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "prev-text",
                              fn: function() {
                                return [
                                  _c("feather-icon", {
                                    attrs: {
                                      icon: "ChevronLeftIcon",
                                      size: "18"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "next-text",
                              fn: function() {
                                return [
                                  _c("feather-icon", {
                                    attrs: {
                                      icon: "ChevronRightIcon",
                                      size: "18"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1308952388
                        ),
                        model: {
                          value: _vm.currentPage,
                          callback: function($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-3 mb-1" },
                [
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mb-1 mb-sm-0 mr-0 mr-sm-1",
                          attrs: { variant: "primary", block: "" },
                          on: { click: _vm.submitAuthClients }
                        },
                        [_vm._v(" Update Authorized Client Sites ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }